<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
               <b-card-code >
                  <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Report Type</label>
                            <v-select
                            v-model="reporttype"
                            placeholder="None"
                            :options="[&quot;Bill Wise&quot;,&quot;Item Wise&quot; ]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Branch</label>
                            <v-select
                            v-model="branchname"
                            label="name"
                            placeholder="None"
                            :options="branchOptions"
                            @input="getEmployee()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4" v-if=" branchname.name=='Channel Partner' ||  branchname.name=='Franchise'">
                          <b-form-group>
                            <label>{{ branchname.name}}</label>
                            <v-select
                            v-model="employee"
                            label="name"
                            placeholder="None"
                            :options="employeeOptions"
                            @input="getSub()"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Sub Employees</label>
                            <v-select
                            v-model="subemployee"
                            placeholder="None"
                            label="name"
                            :options="subemployeeOptions"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="4">
                          <b-form-group>
                            <label>Supplier Name</label>
                              <v-select
                              v-model="suppliername"
                              placeholder="None"
                              label="name"
                              :options="supplierOptions"
                              />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Approve Type</label>
                            <v-select
                            v-model="approvetype"
                            placeholder="None"
                            :options="[&quot;All&quot;,&quot;Pending&quot;,&quot;Approved&quot;,&quot;Reject&quot; ]"
                            />
                          </b-form-group>
                        </b-col>
                         <b-col md="4">
                          <b-form-group>
                            <label>Account Head</label>
                            <b-form-input
                            v-model="accounthead"
                            placeholder="Enter Account Head"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Date</label>
                            <flat-pickr class="form-control"
                              value=""
                              placeholder="Select Date"
                              :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                              }"
                              @input="getdata($event)"
                              style="background-color: transparent"/>
                          </b-form-group>
                        </b-col>

                      </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                  </validation-observer>
              </b-card-code>
              <report-table
                :data="data"
                :columns="fields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import ReportTable from '../../../components/ReportTable.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import vSelect from 'vue-select'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    vSelect,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable
  },
  data () {
    return {
      branchOptions:[{name:'Channel Partner', id:'channelpartner'}, {name:'Franchise', id:'franchise'}, {name:'Admin', id:'admin'}],

      startdate: '',
      enddate: '',
      branchname: '',
      suppliername: '',
      approvetype: '',
      reporttype: '',
      accounthead: '',
      data: '',
      subemployee: '',
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      subemployeeOptions:[],
      employeeOptions:[],
      subemployeeOptions:[],
      supplierOptions:[],
      fields: [
        { field: 'id', label: 'S.R. No', hidden: true,},
        { field: 'invoiceno', label: 'Invoice No',},
        { field: 'invoicedate', label: 'Invoice Date',},
        { field: 'Producrtype', label: 'Product Type',},
        { field: 'partyname', label: 'Party Name',},
        { field: 'ledgername', label: 'Ledger Name',},
        { field: 'hsncode', label: 'HSNCODE',},
        { field: 'qty', label: 'Qty',},
        { field: 'uom', label: 'UOM',},
        { field: 'rate', label: 'Rate',},
        { field: 'disc', label: 'disc',},
        { field: 'Amount', label: 'amount',},
        { field: 'cgst', label: 'CGST %',},
        { field: 'cgstamount', label: 'CGST Amount',},
        { field: 'sgst', label: 'SGST %',},
        { field: 'sgstamount', label: 'SGST Amount',},
        { field: 'igst', label: 'IGST %',},
        { field: 'igstamount', label: 'IGST Amount',},
        { field: 'cess', label: 'CESS %',},
        { field: 'cessamount', label: 'CESS Amount',},
        { field: 'pf', label: 'P & F',},
        { field: 'freigst', label: 'Freigst',},
        { field: 'roundoff', label: 'Round Off',},
        { field: 'grandtotal', label: 'Grand Total',}
      ]
    }
  },
  mounted () {
    this.getSupllier()
  },
  methods: {
    async getSupllier () {
      await axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/ledger`
      })
        .then((response) => {
          this.supplierOptions = response.data.data.data
        })
    },
    async getEmployee () {
      this.employee = '',
      this.subemployeeOptions = [],
      this.subemployee = ''
      if (this.branchname.name == 'Channel Partner') {
        await axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
          },
          url: `${this.baseApi}/getChannelpartner`
        })
          .then((response) => {

            this.employeeOptions = response.data.data
          })
      } else if (this.branchname.name == 'Franchise') {
        await axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
          },
          url: `${this.baseApi}/getFranchise`
        })
          .then((response) => {
            this.employeeOptions = response.data.data
          })
      } else if (this.branchname.name == 'Admin') {
        await axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
          },
          url: `${this.baseApi}/getUsers`
        })
          .then((response) => {
            this.subemployeeOptions = response.data.data
          })
      }
    },
    getSub () {
      this.subemployeeOptions = []
      this.subemployee = ''
      this.employeeOptions.map((item) => {
        if (item.parent == this.employee.id) {
          this.subemployeeOptions.push(item)
        }
      })
    },
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async getDropdowns () {
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    },
    async searchData (tableData) {
      const data = {
        startdate:this.startdate,
        enddate:this.enddate,
        branch:this.branchname.id,
        employee_id:this.subemployee.id,
        suppliername: this.suppliername.id,
        approvetype: this.approvetype,
        reporttype: this.reporttype,
        acchead: this.accounthead
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/otherpurchasereport`
      })
        .then((response) => {
          this.data = response.data.data
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
